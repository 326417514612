import { Icon } from '@maverick/ui';
import { IconEdit } from '@maverick/icons/dist/IconEdit';
import { FC, useState, useEffect } from 'react';
import { useCookies, useInterval } from '@maverick/hooks';
import { formatTime, GaEvent, getDateWithOffset } from '@maverick/utils';
import { differenceInMilliseconds, format } from 'date-fns';
import Link from 'next/link';
import { LocationReduxProps } from '.';
import { setCookie } from '@maverick/ui';
import { Calendar, Cookies, Restaurant } from '@maverick/entity';
import { LocationManager } from '@maverick/cms';
import { LocationIcon } from '@maverick/icons/dist/LocationIcon';

import * as Styled from './Location.styled';

const refreshInterval = Number(process.env.LOCATION_REFRESH_INTERVAL) ?? 3600;

interface LocationProps extends LocationReduxProps {}

export const Location: FC<LocationProps> = ({ seatingData }) => {
	const [open, setOpen] = useState<boolean | null>(null);
	const [tableWait, setTableWait] = useState<string | null>(null);
	const [hasSeating, setHasSeating] = useState<boolean>(false);
	const [yextUrl, setYextUrl] = useState<string | null>(null);
	const [restaurant, setRestaurant] = useState<Restaurant | null>(null);
	const [favoriteStore] = useCookies(Cookies.FavoriteStore);

	useEffect(() => {
		initFavoriteStore();
	}, [favoriteStore]);

	useEffect(() => {
		handleSec();
	}, [restaurant]);

	useEffect(() => {
		handleSeating();
	}, [seatingData]);

	useInterval(() => {
		handleSeating();
		handleSec();
	}, refreshInterval * 1000);

	const initFavoriteStore = async () => {
		if (!favoriteStore) {
			setRestaurant(null);
			GaEvent.Global.Set('store_id', 'not selected');
			return;
		}

		const restaurant = await LocationManager.GetLocationByRef(favoriteStore);

		if (!restaurant) {
			setRestaurant(null);
			GaEvent.Global.Set('store_id', 'not selected');
			return;
		}

		setRestaurant(restaurant);
		GaEvent.Global.Set('store_id', Number(favoriteStore));
	};

	const handleSec = async () => {
		if (!favoriteStore || !restaurant) {
			return;
		}

		await Promise.all([handleYextUrl(), handleRestaurantOpen(), handleTableWait()]);
	};

	const handleYextUrl = async () => {
		const result = await LocationManager.GetLocationInfo(favoriteStore!);
		if (!result) return;

		if (result.yextUrl) {
			setYextUrl(result.yextUrl);
		}

		setCookie(Cookies.WhitelabelUrl, restaurant!.url);
	};

	const handleRestaurantOpen = async () => {
		if (!restaurant) return;

		if (restaurant?.calendars) return;

		const result = await LocationManager.GetLocationCalendars(restaurant.id);
		if (!result) {
			return;
		}

		const now = getDateWithOffset(restaurant.utcoffset);
		const isOpen = result.businessCalendar?.ranges.some((r) => now >= r.start && now <= r.end) ?? null;

		const calendar = () => {
			const calendarArray = [result.businessCalendar, result.curbsideCalendar, result.deliveryCalendar];
			const resultArray: Calendar[] = [];
			calendarArray.forEach((c) => {
				if (!!c) resultArray.push(c);
			});

			return resultArray.length > 0 ? resultArray : undefined;
		};

		const newRestaurant = { ...restaurant, calendars: calendar() };
		setRestaurant(newRestaurant);
		setOpen(isOpen);
	};

	const handleTableWait = async () => {
		const result = await LocationManager.GetTableWait(favoriteStore!);
		if (!result) {
			return;
		}

		setTableWait(result.waitTime);
	};

	const handleSeating = async () => {
		if (!seatingData?.PartyKey || !seatingData?.Datetime) {
			setHasSeating(false);
			return;
		}

		if (seatingData.PartyKey && restaurant) {
			const restaurantDate = getDateWithOffset(restaurant.utcoffset);
			const isInTheFuture = differenceInMilliseconds(seatingData.Datetime, restaurantDate) > 0;
			if (!isInTheFuture) {
				setHasSeating(false);
			} else {
				setHasSeating(true);
			}
		}
	};

	const renderSeatingDetails = (): string => {
		if (!hasSeating || !seatingData || !seatingData.TimeSlot) return '';
		return `Party of ${seatingData.Guests}, ${formatTime(seatingData)}`;
	};

	return (
		<Styled.Container>
			{!restaurant ? (
				<Styled.NoLocation
					href='https://locations.outback.com/search'
					data-testid='no-location'
					onClick={() => {
						GaEvent.TopNavigation('Find location');
						GaEvent.OutboundLinkClick('https://locations.outback.com/search');
					}}
				>
					<Styled.LocationIcon>
						<Icon icon={LocationIcon} customSize />
					</Styled.LocationIcon>
					Find location
				</Styled.NoLocation>
			) : (
				<Styled.Content data-testid='content'>
					<Styled.Name>
						<Styled.NameLabel>Your Outback:</Styled.NameLabel>
						<Styled.NameValue
							data-testid='location-name'
							href={`https://locations.outback.com/${!!yextUrl ? yextUrl : 'search'}`}
							onClick={() => {
								GaEvent.TopNavigation(restaurant.name);
								GaEvent.OutboundLinkClick(`https://locations.outback.com/${yextUrl}`);
							}}
						>
							{restaurant.name}
						</Styled.NameValue>
						<Styled.Icon>
							<a
								href='https://locations.outback.com/search'
								onClick={() => {
									GaEvent.TopNavigation('Change');
									GaEvent.OutboundLinkClick('https://locations.outback.com/search');
								}}
							>
								<Icon icon={IconEdit} />
							</a>
						</Styled.Icon>
					</Styled.Name>

					{hasSeating && (
						<>
							<Styled.Seating data-testid='seating'>
								<Link
									href='/seating/details'
									onClick={() => {
										GaEvent.TopNavigation(
											`Party of ${seatingData?.Guests}, ${format(
												seatingData!.Datetime,
												'h:mm aaa'
											)}`
										);
										GaEvent.InternalLinkClick('/seating/details');
									}}
								>
									{renderSeatingDetails()}
								</Link>
							</Styled.Seating>
						</>
					)}

					{!hasSeating && open !== null && (
						<>
							<Styled.Open data-testid='location-open'>{open ? 'Open' : 'Closed'}</Styled.Open>

							{open && tableWait !== null && (
								<Styled.TableWait data-testid='table-wait'>
									<Link
										href='/seating'
										onClick={() => {
											GaEvent.TopNavigation(
												`${
													tableWait.toString() !== '0'
														? `${tableWait} min. wait`
														: 'No wait time'
												}`
											);
											GaEvent.InternalLinkClick('/seating');
										}}
									>
										{tableWait.toString() !== '0' ? `${tableWait} min. wait` : 'No wait time'}
									</Link>
								</Styled.TableWait>
							)}
						</>
					)}
				</Styled.Content>
			)}
		</Styled.Container>
	);
};
